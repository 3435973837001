
.Translation-Wide {
    position: absolute;
    top: 0.5rem;
    right: 2rem;
}

.Translation-Flag-Wide {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    display: inline-block;
}

.Translation-Manual-Wide {
    font: normal normal normal 0.875rem/1.25rem Lato;
    text-decoration: underline;
    letter-spacing: 0;
    color: #157BBF;
}