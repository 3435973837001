.App-Bottom-Wide-Empty {
    height: 7rem;
}

.App-Bottom-Wide-Some {
    height: 4rem;
}

.App-Wide-Some {
    width: 100vw;
    background: transparent url('img/background-foto-Wide-Empty.png') no-repeat left center;
    background-size: cover;

    overflow: auto;
    /* opacity: 0.4 */
}

.App-Wide-Empty {
    height: 100vh;
    width: 100vw;
    background: transparent url('img/background-foto-Wide-Empty.png') no-repeat left center;
    background-size: cover;

    overflow: auto;
    /* opacity: 0.4 */
}

.App-Title-Wide-Some {
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    width: 100%;
    margin-top: 0;
    padding-bottom: 0.25rem;

    letter-spacing: 0;
}

.App-Title-Wide-Some div {
    max-width: 75rem;
    margin: auto;
    text-align: left;
}

.App-Title-Wide-Empty {
    margin: 3.5rem auto auto auto;
    padding: 0;

    max-width: 64.375rem;

    opacity: 1;
    letter-spacing: 0;
    color: #07293F;
}

.App-Title-Main-pl {
    font: normal normal bold 3.7rem/3.7rem Adobe Garamond Pro;
    text-align: left;
    text-justify: inter-word;
    text-transform: uppercase;
}

.App-Title-Main-en {
    font: normal normal bold 2.43rem/2.43rem Adobe Garamond Pro;
    /* 56px/67px */
    text-align: left;
    text-justify: inter-word;
    text-transform: uppercase;
}

.App-Title-Main-en sup {
    text-transform: initial;
}

.App-Title-Sub {
    font: normal normal normal 1.5rem/1.5rem Lato;
    /* 24px/34px */
    text-align: left;
}


