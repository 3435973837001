
.Footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #07293F 0 0 no-repeat padding-box;
    opacity: 1;
}

.Footer-Center-Wide{
    margin: auto;
    width: 59.375rem;
}

.Footer-info, .Footer-text, .Footer-small-text {
    padding: 8px;

    display: inline-block;
    width: fit-content;
    vertical-align: middle;
}

.Footer-text {
    font: normal normal normal 1rem/1.72rem Lato;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    text-align: left;
}

.Footer-small-text {
    width: 20rem;
    font: normal normal normal 0.75rem/1rem Lato;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 0.75;
    text-align: left;
}

.Footer-half {
    display: inline-block;
    min-width: 47%;
}
