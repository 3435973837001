
body {
  border: 0;
  margin: 0;
  padding: 0;
  min-height: 100%;
  width: 100%;
}

/*
TODO: backup dla fontów, fonty google?
 */

#root {
  height: 100vh;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

