.Inputs-Wide {
    margin: auto;
    width: 64.375rem;
    padding: 2.5rem;

    font: normal normal normal 0.875rem/1.25rem Lato;
    /* width: 1112px;
       height: 441px; */

    /* UI Properties */
    background: #F2F9FD 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 8px;
    opacity: initial;
}

.Inputs-Query-Div-Wide, .Inputs-SearchType-Div-Wide {
    height: 4.875rem;

    display: inline-block;
    vertical-align: top;
}

.Inputs-Label-Wide {
    text-align: left;
    font: normal normal 600 0.875rem/1.25rem Lato;
    letter-spacing: 0;
    color: #07293F;
    display: block;
}

.Inputs-Query-Div-Wide {
    width: 39.875rem;
    margin-right: 0.25rem;
}

.Inputs-Query-Wide {
    /* 654px, 56px */
    width: 38.875rem;
    height: 3.5rem;
    padding: 0 0.5rem 0 0.5rem;
    font: normal normal normal 1rem/1rem Lato;

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 3px #00000029;
    box-sizing: content-box;
    border: 1px solid #CEE0E5;
    border-radius: 4px;
}

.Inputs-SearchType-Div-Wide {
    width: 10.125rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
}

.Inputs-SearchType-Wide {
    /* 146px; 56px; */
    height: 3.5rem;
    width: 9.125rem;
    padding: 0 0.5rem 0 0.5rem;

    font: normal normal normal 1rem/1rem Lato;

    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 3px #00000029;
    box-sizing: content-box;
    border: 1px solid #CEE0E5;
    border-radius: 4px;
}

.Inputs-Submit-Wide {
    width: 12.5rem;
    height: 3.5rem;
    vertical-align: bottom;
    margin-left: 0.25rem;
    margin-right: 0;

    background: #157BBF 0 0 no-repeat padding-box;
    box-shadow: 0 0 3px #00000029;
    border-radius: 4px;
    box-sizing: content-box;
    border: 1px solid #CEE0E5;
    color: #FFFFFF;
    font: normal normal normal 1rem/1rem Lato;
}

.Inputs-QueryLink-Wide {
    margin-top: 1rem;
    margin-bottom: 1rem;

    font: normal normal normal 0.875rem/1.25rem Lato;
    text-decoration: underline;
    letter-spacing: 0;
    color: #157BBF;
}

.Inputs-Sources-Wide {
    margin: 1rem 0 0 0;
    margin-inline: 0;
    display: block;
    padding-block: 0;
    padding-inline: 0;
    padding: 1.5rem;

    background: #FFFFFF 0 0 no-repeat padding-box;
    border: 1px solid #9DB8CB;
    border-radius: 8px;

    min-inline-size: min-content;
}