.Data-Bottom-Wide {
    height: 7rem;
}

.Data-Wide {
    margin: auto;
    max-width: 75rem;
    font: normal normal normal 1rem/1.375rem Lato;
    letter-spacing: 0;
    color: #07293F;
    opacity: 1;
}

.Data-Header-Wide {
    font: normal normal normal 3rem/3.185rem Adobe Garamond Pro;
}

.Data-SourceHeader-Wide {
    font: normal normal normal 2rem/2.5rem Adobe Garamond Pro;
}

.Data-SubHeader-Wide {
    font: normal normal 600 1.5rem/2.125rem Lato;
    margin-top: 1rem;
}

.Data-Div-Wide {
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 3 #00000029;
    border: 1px solid #E2EDF3;
    border-radius: 8px;
    opacity: 1;
    padding: 2rem 1rem 2rem 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.Data-Tag-Wide {
    font: normal normal bold 1rem/1.375rem Lato;
}

.Data-Value-Wide {
    font: normal normal normal 1rem/1.375rem Lato;
}

.Data-Div-Wide table thead th {
    font: normal normal normal 0.75rem/1rem Lato;
    color: #848E94;
    padding: 0.5rem 1rem;
}

.Data-Div-Wide table thead th:first-child {
    padding-left: 0;
}

.Data-Div-Wide table tbody tr td {
    padding: 0.5rem 1rem;
    border-top: 1px solid #E2EDF3;
}

.Data-Div-Wide table tbody tr:first-child td {
    border: 0;
}

.Data-Div-Wide table {
    border-collapse: collapse;
}

.Data-Div-Wide table tbody tr {
    margin: 0;
}

.Data-Div-Wide table tbody td:first-child {
    padding-left: 0;
}

.Data-Div-Wide table tbody tr td:nth-child(even) {
    background-color: #F2FAFD;
}

.Data-Number {
    text-align: right;
}

.Data-String {
    text-align: left;
}

.Data-Line-Wide {
    border-top: 1px solid #E2EDF3;
    padding: 0.5rem 0
}

.Data-Line-Wide:first-child {
    border: 0;
}

.Data-Padded-Wide {
    padding: 0.5rem 1rem;
}

.Data-Quote-Wide {
    line-height: 1.25rem;
    font-weight: normal;
    font-variant: normal;
    font-style: italic;
    font-size: 0.875rem;
    font-family: Lato;
    color: #516978;
}

.Data-At {
    color: initial;
}

.Data-R {
    font-style: normal;
}

.Data-Hash {
    font-weight: bold;
}

.Data-Expand-Wide {
    text-decoration: underline;
    font: normal normal normal 0.75rem/1.25rem Lato;
    letter-spacing: 0;
    color: #157BBF;
}

.Hidden {
    display: none;
}